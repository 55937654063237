<script setup>
import {ref} from "vue";

const tabs = ref([
  { title: '综合排序',  shrink: 0, content: 'Content for Tab 1' },
  { title: '总价', shrink: 1,  content: 'Content for Tab 2' },
  { title: '单价', shrink: 1,  content: 'Content for Tab 3' },
  { title: '面积', shrink: 1,  content: 'Content for Tab 4' },
  { title: '最新', shrink: 0,  content: 'Content for Tab 5' },
]);

const activeTab = ref(0);

</script>

<template>
  <div class="tabs">
    <ul class="tab-list" style="list-style: none;display: flex">
      <li
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{ 'is-active': activeTab === index }"
          @click="activeTab = index"
          style="margin-right: 1rem"
      >
        {{ tab.title }}
        <el-icon v-if="tab.shrink === 1" size="10"><SortUp /></el-icon>
      </li>
    </ul>
    <div class="tab-content">
      <el-scrollbar height="70vh"
                    v-for="(tab, index) in tabs"
                    :key="index"
                    v-show="activeTab === index"
      >
        <span>{{ tab.content }}</span>
        <ul>
          <li style="display: flex;padding: 12px 0;border-bottom: 1px solid #f0f0f0;" v-for="m in 10">
            <div>
              <el-image style="width:105px; height: 80px" src="/image/2.jpg" fit="fill"></el-image>
            </div>
            <div style="margin-left: 16px;">
              <div style="font-size: 16px;line-height: 16px;">整租·倪家堰路446号 1室0厅</div>
              <div style="font-size: 11px;line-height: 11px;    padding-top: 8px;">30m²|1室1卫|南</div>
              <div style="font-size: 11px;line-height: 11px;    padding-top: 8px;">
                <span>近地铁</span>
                <span>近地铁</span>
                <span>近地铁</span>
              </div>
              <div style="color: #fe615a;font-size: 16px;line-height: 16px; padding-top: 4px;">1250元/月</div>
            </div>
          </li>
        </ul>
      </el-scrollbar>

    </div>
  </div>
</template>

<style scoped>
.tab-list li.is-active {
  color: #3072f6;
}
</style>