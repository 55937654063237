<script setup>

</script>

<template>
  <button style="padding: 1rem;border-radius: 0.5rem;background-color: crimson">
    <slot></slot>
  </button>
</template>

<style scoped>

</style>

<script>
export default {
  props: {
    // 允许用户传递自定义的渲染函数
    customRender: {
      type: Function,
      default: () => {}
    }
  },
  render(h) {
    // 在适当的位置使用自定义渲染函数
    return h('div', this.$slots.default.map(node =>
        node.tag === 'template' && node.attrs.slot === 'custom'
            ? this.customRender(h, node.children)
            : node
    ));
  }
};
</script>