<script setup>

</script>

<template>
  <el-card class="h_margin_bottom">
    <div class="title-theme mb10">友联</div>
    <a-list :bordered="false">
      <a-list-item v-for="idx in 4" :key="idx">
        <router-link to="/">
          <a-list-item-meta
              title="夜愿云"
              description="新生代云服务器"
          >
            <template #avatar>
              <a-avatar shape="square">
                <img
                    alt="avatar"
                    src="https://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/3ee5f13fb09879ecb5185e440cef6eb9.png~tplv-uwbnlip3yd-webp.webp"
                />
              </a-avatar>
            </template>
          </a-list-item-meta>
        </router-link>
      </a-list-item>

    </a-list>
  </el-card>
</template>

<style scoped>
.title-theme {
  position: relative;
  padding-left: 1.2em;
  font-size: 15px;
}

.title-theme:before {
  position: absolute;
  content: '';
  width: 4px;
  background: #00a2e3;
  top: 10%;
  left: 2px;
  bottom: 10%;
  border-radius: 5px;
  box-shadow: 1px 1px 3px -1px #00a2e3;
}
</style>