<script>

import axios from "axios";

export default {
  components: {},
  data() {
    return {
      data: [],
    };
  },
  created: function () {

    const fid = this.$route.params.id; // 确保 wid 在这里可用
    const url = MICRO_BLOG+`/v1/weibo/forum/${fid}`;
    axios.get(url)
        .then((response) => {
          this.data = response.data.data;
          console.log(this.data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
  }
};
</script>

<script setup>
import {ref, onMounted} from "vue";
import axios from "axios";
import {useRoute} from "vue-router";

const loading = ref(true);
const data = ref();
const data1 = ref([
  {id: 200, title: 'lucy的第1条微博', share: 0, reading: 0}]);
const setLoading = () => {
  loading.value = true
  setTimeout(() => {
    loading.value = false
  }, 800)
};
const tagCod = ref();
const route = useRoute();
onMounted(() => {
  setLoading()
  axios.get(MICRO_BLOG+'/v1/weibo/queryTags')
      .then((response) => {
        tagCod.value = response.data.data;
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

  const fid = route.params.id; // 确保 wid 在这里可用
  console.log(fid)
  const url = MICRO_BLOG+`/v1/weibo/forum/${fid}`;
  axios.get(url)
      .then((response) => {
        data.value = response.data.data;
        console.log(data.value)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  if (data.value != null) {
    loading.value = false
  }

})
</script>


<template>
  <el-skeleton
      :loading="loading"
      animated
      :throttle="0">
    <template #template>
      <el-table :data="data1" style="width: 100%;margin-bottom: 1rem">
        <el-table-column prop="title" label="Date" min-width="310">
          <div style="float: left;margin-right: 1rem;width: 40px">
            <el-skeleton-item variant="image" style="width: 40px; height: 40px"/>
          </div>
          <div style="float: left;width: 80%;margin-top: 10px;">
            <el-skeleton-item variant="h3" style="width: 100%"/>
          </div>

        </el-table-column>
        <el-table-column prop="name" label="作者" width="170">
          <div style="float: left;margin-right: 0.5rem;width: 40px">
            <el-skeleton-item variant="image" style="width: 40px; height: 40px"/>
          </div>
          <div style="float: left;width: 60%">
            <el-skeleton-item variant="text" style="width: 100%"/>
            <el-skeleton-item variant="text" style="width: 100%"/>
          </div>
        </el-table-column>
        <el-table-column prop="address" label="回复" width="80">
          <el-skeleton-item variant="text" style="width: 100%"/>
          <el-skeleton-item variant="text" style="width: 100%"/>
        </el-table-column>
        <el-table-column prop="address" label="最后发表" width="130">
          <el-skeleton-item variant="text" style="width: 100%"/>
          <el-skeleton-item variant="text" style="width: 100%"/>
        </el-table-column>
      </el-table>
    </template>
    <template #default>
      <el-table :data="data" style="width: 100%;margin-bottom: 1rem">
        <el-table-column prop="title" label="Date" min-width="562">
          <template v-slot="scope">
            <div style="float: left;margin-right: 1rem">
              <a :href="'/u/'+scope.row.userId">
                <el-avatar shape="square" :src="scope.row.avatar"></el-avatar>
              </a>
            </div>
            <div style="float: left;margin-right: 0.2rem">
              <span class="articleList-option-span-tag" v-if="scope.row.tagCod!=null" v-for="m in scope.row.tagCod.split(',')">
            <span v-for="n in tagCod">
              <router-link to="./">
                <el-text v-if="n.id == m">
                  <el-tag :type="n.colour" :effect="n.effect" class="h_margin_right">
                    {{ n.content }}
                  </el-tag>
                </el-text>
              </router-link>
            </span>
          </span>
            </div>
            <div style="float: left;width: 370px">
              <a class="articleList-option-a" :href="'../h/' +scope.row.id">
        <span class="articleList-option-title">
          {{ scope.row.title }}
        </span>
              </a>
            </div>

          </template>
        </el-table-column>
        <el-table-column prop="name" label="作者" width="170">
          <template v-slot="scope">
            <div style="float: left;margin-right: 0.5rem;height: 40px">
              <a :href="'/u/'+scope.row.userId">
                <el-avatar shape="square" :src="scope.row.avatar"></el-avatar>
              </a>
            </div>
            <div style="float: left;">
              <a :href="'/u/'+scope.row.userId">
                <span class="articleList-option-span">{{ scope.row.userName }}</span>
              </a>
              <span class="articleList-option-span articleList-option-span-small">{{ scope.row.timeDate }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="回复" width="80">
          <template v-slot="scope">
      <span class="articleList-option-span">
        {{ scope.row.commentsNUM }}
      </span>
            <span class="articleList-option-span">
        {{ scope.row.reading }}
      </span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="最后发表" width="130">
          <template v-slot="scope">
            <a :href="'/u/'+scope.row.userId2">
      <span class="articleList-option-span">
        {{ scope.row.commentUsername }}
      </span>
            </a>
            <span class="articleList-option-span articleList-option-span-small">
       {{ scope.row.finalComment }}
      </span>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </el-skeleton>
</template>

<style scoped>
.articleList-option-title {
  display: flex;
  line-height: 40px;
  width: 100%; /* 设置你想要的宽度 */
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏超出容器的文本 */
  text-overflow: ellipsis; /* 显示省略号 */
}

.articleList-option-span-tag {
  display: flex;
  float: left;
  line-height: 40px;
}

.articleList-option-span {
  line-height: 20px;
  display: block;
}

.articleList-option-span-small {
  font-size: 12px;
  color: #666666;
}
</style>