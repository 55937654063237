<template>
  <router-view/>
</template>



<script setup>

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
:root{
  --xiaohai1:1rem;
  --main-main-hiloney: 1050px;
}
a {
  text-decoration: none;
}
*{
  margin: 0;
  padding: 0;
}

</style>
