import {get, post} from '@/api/http'
import {ElMessage} from "element-plus";
import { useStore } from 'vuex';
import { createStore } from 'vuex';
const store = createStore();
export function regUser(stringify, messages) {
    return post('/user/reg', stringify, messages)
}

export function getCode(account, messages) {
    return get('/user/validate?account=' + account, null, messages)
}


async function loginStatusChange(){
    await store.dispatch('login');
}
export function qqLogin() {
    return get('/qq/login', null, null).then((value) =>window.open(value.data, '_self'))
}
