<script setup>
import {onMounted, ref ,reactive,computed} from "vue";
import {ElMessage} from "element-plus";
import { useStore } from 'vuex';
const store = useStore();
import {User,Lock,ChatDotSquare} from "@element-plus/icons-vue";
import axios from "axios";
import qs from "qs";
import {getCode, qqLogin, regUser} from "@/api/api"
import {post} from "@/api/http";
// 使用 ref 定义 props
const props = defineProps({
  dialogVisible: {
    type: Boolean,
    required: true
  },
  clickToSwitchIn: {
    type: Object,
    required: true
  }
});


const displayMethod = ref([
  {title:"扫码登录"},
  {title:"邮箱登录"},
  {title:"账号登录"},
]);

const clickToSwitch = ref(props.clickToSwitchIn);
const emit = defineEmits(['dataFromChild']);

const qrCodeColor = ref([
    1,1,1,1,1,1,1,0,1,1,1,0,1,1,0,1,1,1,0,0,1,0,0,0,1,1,0,1,1,0,1,1,0,0,1,1,1,1,1,1,1,
    1,0,0,0,0,0,1,0,1,0,1,0,0,1,0,1,1,1,0,1,0,1,0,0,1,1,1,1,0,1,0,0,1,0,1,0,0,0,0,0,1,
    1,0,1,1,1,0,1,0,0,1,0,1,0,1,0,1,1,1,0,0,0,1,1,0,1,1,0,1,1,1,0,0,0,0,1,0,1,1,1,0,1,
    1,0,1,1,1,0,1,0,1,1,1,1,1,0,1,1,1,1,0,1,1,0,1,0,0,1,1,1,1,1,0,1,1,0,1,0,1,1,1,0,1,
    1,0,1,1,1,0,1,0,1,1,1,1,0,0,0,0,1,0,0,1,1,1,0,0,1,0,1,1,0,0,0,0,1,0,1,0,1,1,1,0,1,
    1,0,0,0,0,0,1,0,1,0,0,0,1,0,1,1,1,0,1,1,0,0,1,1,1,0,1,1,0,0,0,1,0,0,1,0,0,0,0,0,1,
    1,1,1,1,1,1,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,1,1,1,1,1,1,
    0,0,0,0,0,0,0,0,0,1,1,0,1,1,0,1,0,1,0,1,0,0,1,0,1,0,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,
    0,0,0,1,0,0,1,0,0,0,0,0,1,1,0,0,1,0,1,1,0,1,1,1,1,1,1,0,1,0,0,0,1,0,0,1,1,1,0,1,1,
    0,0,1,0,0,0,0,1,0,1,1,1,1,1,0,0,1,1,0,0,0,1,1,1,0,1,1,0,1,1,1,0,1,0,1,0,0,1,1,1,0,
    0,1,0,1,0,0,1,0,1,0,0,0,0,0,1,0,1,1,0,0,1,0,0,0,0,1,1,0,0,0,1,1,0,0,0,1,0,1,1,0,0,
    1,0,0,0,1,0,0,0,1,1,0,1,0,1,0,0,1,0,0,1,1,1,1,1,1,1,0,1,1,0,1,0,0,0,0,0,0,0,1,0,0,
    1,1,1,0,0,1,1,0,0,1,0,0,1,1,1,0,1,1,1,1,1,0,0,0,0,1,0,1,0,1,0,1,1,0,1,0,0,1,0,0,1,
    1,0,0,0,0,1,0,1,0,1,1,0,0,1,0,1,0,0,0,0,0,1,0,0,1,1,1,1,1,1,1,0,1,0,1,0,0,0,0,0,0,
    0,1,0,1,1,1,1,1,0,1,0,0,1,1,0,0,1,1,0,1,1,0,0,0,0,1,1,0,0,1,1,1,0,0,1,0,0,1,0,1,1,
    0,1,0,1,1,1,0,0,0,1,0,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,1,0,1,1,0,1,0,1,1,0,0,0,0,0,
    0,0,0,0,0,1,1,1,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,1,0,0,1,1,0,0,0,0,1,0,0,0,1,0,
    0,0,1,0,0,0,0,1,1,0,1,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,0,1,0,1,1,0,0,1,1,1,
    1,0,1,0,1,1,1,0,1,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,1,0,0,1,0,0,1,1,0,0,1,0,1,1,1,
    0,0,1,1,0,1,0,1,1,0,1,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,0,0,1,0,0,0,1,0,1,0,1,1,
    0,1,1,0,0,1,1,1,0,0,1,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,1,0,1,0,0,1,1,1,1,1,1,1,0,1,
    1,0,0,1,0,0,0,1,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,1,1,0,1,0,1,1,1,
    0,1,1,0,1,1,1,1,0,0,1,0,1,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,0,0,0,0,0,1,0,1,0,
    1,1,0,0,1,0,0,1,1,0,1,1,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,1,1,0,0,0,0,
    1,1,1,0,0,1,1,1,0,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,0,0,0,1,1,0,
    0,0,0,1,1,0,0,0,1,1,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,0,0,1,0,1,0,1,0,0,0,1,0,0,0,
    0,1,0,1,1,0,1,0,0,0,0,1,0,0,0,1,0,1,1,1,0,1,0,0,1,0,1,0,0,0,0,0,1,1,0,1,1,0,0,0,1,
    1,1,1,0,0,1,0,1,1,1,0,1,1,0,0,1,1,0,0,0,1,1,1,0,1,1,0,1,0,1,1,0,1,1,1,1,0,0,0,1,1,
    1,1,0,0,0,0,1,1,1,1,0,0,0,1,0,0,1,1,1,0,1,1,0,0,1,0,1,0,0,1,1,0,0,1,1,0,0,0,0,1,0,
    0,1,0,1,1,0,0,1,1,0,1,0,1,0,1,0,0,0,1,0,1,0,1,1,1,1,1,1,1,0,1,1,1,1,0,1,0,1,0,0,1,
    1,0,1,0,0,1,1,0,0,0,1,0,1,0,0,0,0,0,0,0,0,1,0,0,0,0,0,1,1,1,1,0,0,0,0,0,0,1,1,0,1,
    0,0,0,1,0,0,0,0,1,0,1,1,1,1,1,1,0,1,0,1,1,1,1,0,1,0,0,0,0,1,1,0,0,1,0,0,1,0,0,1,1,
    1,0,1,1,1,1,1,1,0,0,1,0,0,1,0,1,0,0,1,1,0,0,1,1,1,0,0,1,0,0,0,0,1,1,1,1,1,0,1,1,1,
    0,0,0,0,0,0,0,0,1,0,0,0,1,1,1,1,0,0,1,0,1,1,0,1,1,1,1,0,1,1,0,0,1,0,0,0,1,1,0,0,1,
    1,1,1,1,1,1,1,0,0,1,1,1,1,0,1,1,1,1,1,1,0,1,0,1,1,1,0,0,1,0,1,0,1,0,1,0,1,0,0,1,0,
    1,0,0,0,0,0,1,0,0,0,1,1,1,1,0,1,0,0,1,1,1,0,0,1,1,1,0,0,0,0,1,0,1,0,0,0,1,0,1,0,0,
    1,0,1,1,1,0,1,0,0,1,0,0,0,0,0,0,1,0,1,0,1,1,0,0,0,1,0,0,0,0,1,0,1,1,1,1,1,1,0,0,0,
    1,0,1,1,1,0,1,0,1,1,0,1,1,0,0,1,0,1,0,0,1,1,1,1,1,1,1,0,1,0,1,0,1,1,1,0,1,0,0,1,0,
    1,0,1,1,1,0,1,0,0,1,1,0,0,0,0,1,0,0,0,0,1,0,0,1,0,0,1,0,0,0,0,1,1,0,0,0,1,1,0,0,1,
    1,0,0,0,0,0,1,0,0,1,1,1,0,0,0,1,0,1,1,1,0,1,1,1,1,1,0,1,0,1,0,1,1,0,1,1,0,1,0,1,0,
    1,1,1,1,1,1,1,0,0,1,0,0,0,1,1,1,1,0,0,1,1,0,1,0,0,0,1,0,0,1,0,1,1,1,0,1,1,0,1,1,0,
]);
const user = reactive({
  account: '',
  code: '',
  password: ''
});


const open = ()=>{
 //改变.filter-xh类 的filter属性
  document.querySelector('.filter-xh').style.filter = 'blur(10px)';

}

const clickToSwitchClick = (a)=> {
  user.account = null;
  user.code = null;
  user.password = null;
  clickToSwitch.value.id = a;
}
// 使用 reactive 定义 data
const userReg = reactive({
  email: '',
  code: '',
  password: ''
});

const handleClose = (done)=> {
  onDialogClosing();
  done(); // 必须调用 done 方法来确保对话框能够正确关闭
}

const onDialogClosing = ()=> {
  emit('dataFromChild', false);
  document.querySelector('.filter-xh').style.filter = 'blur(0)';
}
const remainingTime = ref(32);
let timer = null;

const startCountdown = async ()=> {
  remainingTime.value = 30;
  timer = setInterval(() => {
    if (remainingTime.value > 0) {
      remainingTime.value--;
    } else {
      remainingTime.value = 31;
      stopCountdown();
      // 在这里可以添加时间到后需要执行的操作，比如触发一个事件等
    }
  }, 1000); // 每秒更新一次剩余时间
}

const stopCountdown = ()=>  {
  clearInterval(timer);
}
const obtainCode = (account)=>{
  console.log(account)
  let regex = /(^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$)|(^[1-9]\d{4,9}$)/;
  console.log(!regex.test(account))
  if (!regex.test(account)) {
    ElMessage.error("邮箱格式错误");
  }else {
    getCode(account,'验证码已发送至邮箱')
    startCountdown();
  }
}
const reg = (user)=>{
  userReg.email = user.account;
  userReg.password = user.password;
  userReg.code = user.code;
  let regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  let regpassword = /^[a-zA-Z0-9_]{6,20}$/;
  let verificationCode = /^[a-zA-Z0-9]{6}$/;
  let stringify = qs.stringify(userReg);
  if (!regex.test(userReg.email)) {
    ElMessage.error("邮箱格式错误");
  }else if (!regpassword.test(userReg.password)) {
    ElMessage.error("密码格式不符，请输入A-Z0-9a-z_等字符并在6到20位之间");
  }else if (!verificationCode.test(userReg.code)){
    ElMessage.error("请输入6位验证码");
  }else{
    //注册请求
    regUser(stringify,'注册成功')
  }
}

const loginUser = (url, stringify, messages)=> {
  post('/user/' + url, stringify).then(data => {
    if (data.code === 2001) {
      localStorage.setItem("token", data.data.token)
      jump();
      ElMessage.success(messages);
    } else {
      console.log(data.code)
      ElMessage.error(data.msg)
    }
    return data
  });
}

const login = (user,num)=>{
  let stringify = qs.stringify(user);
  switch (num){
    case 2:
      loginUser('emailLogin',stringify,'登录成功')
      break;
    case 3:
      loginUser('passwordLogin',stringify,'登录成功')
      break;
    default:
      loginUser('login',stringify,'登录成功')
      break;
  }
}
const qqURL = ref();

const jump = async ()=> {
  onDialogClosing()
  await store.dispatch('login');
}
const getQQQrCode = ()=> {
  qqLogin();
  // console.log(qqURL.value)
  // axios.get(BASE_URL+"/qq/login").then((response) => {
  //   qqURL.value = response.data;
  //   //跳转到qq登录页
  //   window.open(qqURL.value, '_self');
  // })
}
</script>

<template>
  <el-dialog width="620" class="xuanzhong" :modal="false" :before-close="handleClose" @open="open" @close="onDialogClosing" v-model="props.dialogVisible" align-center style="padding:0;border-radius: var(--xiaohai1); --header-bg-color: transparent;">
    <template #header><div style="margin: -17px"></div></template>
    <div style="margin: 0 0 -3px 0;display: flex;width: 100%;height: 100%;">
      <div class="cascasc" style="float: left;width: 239px;">
        <el-image style="width: 239px;;border-top-left-radius: var(--xiaohai1);border-bottom-left-radius: var(--xiaohai1)" src="/img_1.png">
        </el-image>
      </div>
      <div style="float: right;width: calc(100% - 239px);padding: 0 48px;margin-top: 50px;overflow: hidden">
        <div>
          <ul v-if="clickToSwitch.id < 4" style="list-style: none;padding: 0;justify-content: space-between;display: flex;">
            <li class="login-ul-li" v-for="(m,index) in displayMethod" :key="index" >
              <span @click="clickToSwitchClick(index+1)" :class="'login-ul-li-span-button '+ (clickToSwitch.id==index+1?'clickToS':'')">{{ m.title }}</span>
              <div v-if="clickToSwitch.id == index+1" class="login-ul-li-div-horizontalLine"/>
            </li>
          </ul>
          <div v-if="clickToSwitch.id == 4" style="padding: 0;justify-content: space-between;text-align: center">
            <div class="login-div-span">
              <span>注册账号</span>
            </div>
          </div>
        </div>

        <el-form style="margin-top: 10px;" ref="form" :model="user">
          <el-form-item>
            <el-input v-if="clickToSwitch.id != 1" :prefix-icon="User" class="login-form-input" v-model="user.account" placeholder="请输入账号/邮箱"></el-input>
            <div  v-else style="margin:0 auto;width: 124px;border: 1px solid rgba(0,0,0,0.1);border-radius: 0.5rem;padding: 1rem">
              <div class="qrCodeColor-father">
                <img src="/favicon.ico" class="qrCodeColor-logo" alt="">
                <div v-for="m in qrCodeColor" :class="'qrCodeColor qrCodeColor-'+(m===1?'black':'white')"/>
<!--                <router-view v-wechat-title="$route.meta.title"/>-->
<!--                <el-button type="primary" @click="getQQQrCode">buttonCont</el-button>-->
              </div>
              <!--              <img style="" src="/shoukuan.jpg" width="124" height="124" alt="">-->
            </div>
          </el-form-item>
          <el-form-item v-if="clickToSwitch.id != 3">
            <div v-if="clickToSwitch.id == 1" style="margin:0 auto;width: 158px;">
              <span>支持租房网APP 扫码登录</span>
            </div>
            <el-input v-else :prefix-icon="ChatDotSquare" class="login-form-input" v-model="user.code" placeholder="请输入验证码">
              <template #suffix>
                <el-link @click="obtainCode(user.account)">{{ remainingTime===32?'获取验证码':(remainingTime===31?'重新发送':'重新发送('+remainingTime+')') }}</el-link>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item v-if="clickToSwitch.id == 3 ||clickToSwitch.id == 4">
            <el-input :prefix-icon="Lock" class="login-form-input" v-model="user.password" placeholder="请输入密码" type="password" show-password></el-input>
          </el-form-item>
          <el-form-item>
            <el-button v-if="clickToSwitch.id == 2 || clickToSwitch.id == 3" type="primary" style="width: 100%;height: 44px;" @click="login(user,clickToSwitch.id)">登录</el-button>
            <div style="width: 100%">
              <div  v-if="clickToSwitch.id == 2 ||clickToSwitch.id == 3" style="float: left">
                <el-checkbox label="7天内免登录" size="large" style="margin-top: 0" />
              </div>
              <div v-if="clickToSwitch.id == 3" style="float: right;height: 40px;line-height: 40px;">
                <a href="/" class="login-form-div-a" >忘记密码</a>
              </div>
            </div>
            <el-button v-if="clickToSwitch.id == 4" type="primary" style="width: 100%;height: 44px;" @click="reg(user)">注册</el-button>
            <div  v-if="clickToSwitch.id == 4" style="width: 100%">
              <div style="float: right;height: 40px;line-height: 40px;">
                已有账号? <a @click="clickToSwitchClick(2)" class="login-form-div-a" >去登录</a>
              </div>
            </div>
            <el-button v-if="clickToSwitch.id == 3" type="primary" @click="getQQQrCode()">QQ登录</el-button>
          </el-form-item>
        </el-form>
        <div style="position: absolute;bottom:70px;margin-left:-50px;width: 384px;border-top: 1px solid rgba(0,0,0,0.1)"></div>
        <div class="login_protocol protocol">
          登录即代表同意：<a class="link-btn" href="/" target="_blank">永恒社区隐私政策</a>、<a class="link-btn" href="/" target="_blank">永恒用户服务协议</a>
          <div>未注册过的手机号和邮箱，验证通过后自动注册永恒账号</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<style scoped>


.qrCodeColor-father{
  width: 124px;
  height: 124px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  position: relative;
}
.qrCodeColor-logo{
  position: absolute;
  top: calc((124px - 33px) / 2);
  left: calc((124px - 33px) / 2);
  width: 33px;
  height: 33px;
}
.qrCodeColor-black{
  background-color: rgb(0, 0, 0);
}
.qrCodeColor-white{
  background-color: rgba(255,255,255,0);
}
.qrCodeColor{
  width: 3px;
  height: 3px;
}
.clickToS{
  color: #3072F6;
}
.login-ul-li-div-horizontalLine{
  box-shadow: 0 0 2px #3072F6;
  border-radius: 20px;
  margin: 0.5rem auto;
  background-color: #3072F6;
  height: 4px;
  width: 24px;
}
.login-ul-li{
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
}
.login-div-span{
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
}
.login-ul-li-span-button{
  cursor: pointer; /* 设置鼠标指针为手型 */
}
.login-form-input{
  height: 50px;
}
.login_protocol {
  font-size: 13px;
  bottom: calc(32px / 2);
  margin-left: -17px;
  position: absolute;
  line-height: 13px;
}

.login_panel a {
  cursor: pointer;
}
.login_protocol a {
  color: #222;
}
.login_protocol div {
  margin-top: 10px;
  color: #999;
}
.login-form-div-a{
  color: #222;
  text-decoration: none;
  cursor: pointer; /* 设置鼠标指针为手型 */
}
.login-form-div-a:hover{
  text-decoration: underline;
}
</style>