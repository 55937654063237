import { createStore } from 'vuex'



export default createStore({
  state: {
    isLoggedIn: localStorage.getItem('state')==="true"?true:false
  },
  getters: {
  },
  mutations: {
    // 定义一个名为 'SET_LOGIN_STATUS' 的 mutation
    SET_LOGIN_STATUS(state, status) {
      console.log("SET_LOGIN_STATUS方法被调用")
      // 更新状态
      state.isLoggedIn = status;
    }
  },
  actions: {
    login({ commit }) {
      console.log("login方法被调用")
      // 模拟异步登录逻辑
      setTimeout(() => {
        commit('SET_LOGIN_STATUS', true);
        localStorage.setItem("state", true);
      }, 1000);
    },
    logout({ commit }) {
      commit('SET_LOGIN_STATUS',false);
      localStorage.setItem("state", false);
    }
  }  ,
  modules: {
  }
})
