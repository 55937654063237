<script setup>
import {onMounted, ref} from "vue";
import axios from "axios";

const data = ref();
const loading = ref(true);
const setLoading = () => {
  loading.value = true
  setTimeout(() => {
    if (data.value != null){
      loading.value = false
    }
  }, 800)
};
onMounted(()=>{
  setLoading();
  axios.get(MICRO_BLOG+'/v1/weibo/selectIndex1')
      .then((response) => {
        data.value = response.data.data;
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
})
</script>
<template>
      <el-card>
        <div class="title-theme mb10">热门文章</div>
        <a-list :bordered="false">
          <el-skeleton
              :loading="loading"
              animated
              :throttle="0">
            <template #template>
              <div v-for="(m,index) in 10" key="index" style="width: 100%;">
                <a-list-item>
                  <div style="float: right;width: 100%;height: 2rem;line-height: 2.8rem">
                    <el-skeleton-item variant="h3" style="width: 100%"/>
                  </div>
                </a-list-item>
              </div>
            </template>
            <template #default>
              <div v-for="(m,index) in data" key="index" style="width: 100%;">

                <a :href="'http://localhost/bbs/h/'+m.id">
                  <div style="float: left;width: 1.3rem;height: 2rem;line-height: 2rem">
                    <el-text v-if="(index+1)===1" style="text-align: center" type="danger">{{ index+1 }}.</el-text>
                    <el-text v-if="(index+1)===2" style="text-align: center" type="warning">{{ index+1 }}.</el-text>
                    <el-text v-if="(index+1)===3" style="text-align: center" type="success">{{ index+1 }}.</el-text>
                    <el-text v-if="(index+1)>3" style="text-align: center">{{ index+1 }}.</el-text>
                  </div>
                  <div style="float: right;width: calc(100% - 1.3rem);height: 2rem;line-height: 1.99rem">
                    <el-text line-clamp="1">
                      <el-tooltip class="box-item"
                                  effect="light"
                                  :content="m.title"
                                  placement="left">
                        <el-link :underline="false" ><el-text line-clamp="1">{{ m.title }}</el-text></el-link>
                      </el-tooltip>
                    </el-text>
                  </div>
                </a>
              </div>
            </template>
          </el-skeleton>
        </a-list>
      </el-card>


</template>

<style scoped>

.title-theme {
  position: relative;
  padding-left: 1.2em;
  font-size: 15px;
}

.title-theme:before {
  position: absolute;
  content: '';
  width: 4px;
  background: #00a2e3;
  top: 10%;
  left: 2px;
  bottom: 10%;
  border-radius: 5px;
  box-shadow: 1px 1px 3px -1px #00a2e3;
}

.el-popper.is-customized {
  /* Set padding to ensure the height is 32px */
  padding: 6px 12px;
  background: linear-gradient(90deg, rgb(159, 229, 151), rgb(204, 229, 129));
}

.el-popper.is-customized .el-popper__arrow::before {
  background: linear-gradient(45deg, #b2e68d, #bce689);
  right: 0;
}
</style>