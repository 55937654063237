import axios from 'axios'
import {ElMessage} from "element-plus";

//在开发环境中的测试 development
if (process.env.NODE_ENV == 'development') {
    axios.defaults.baseURL = 'http://127.0.0.1:8080/'
}
//在生产环境中的测试 production
if (process.env.NODE_ENV == 'production') {
    axios.defaults.baseURL = 'https://api.fivemhy.cn/'
}
//还有一种环境 debug


axios.defaults.timeout = 5000;

//接口请求拦截
axios.interceptors.request.use(
    config => {
        config.headers = {DeviceType: 'H5'} //设置响应头部
        return config
    }
)

export function get(url, params, messages) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res)
            if (messages!=null){
                success(res,messages)
            }
        }).catch(err => {
            reject(err)
            error(err)
        })
    })
}

export function post(url, params, messages) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(res => {
                resolve(res.data)
                if (messages!=null){
                    success(res,messages)
                }
            })
            .catch(err => {
                reject(err.data)
                error(err)
            })
    })
}

function success(response,messages) {
    if (response.data.code == 2001) {
        ElMessage.success(messages);
    } else {
        ElMessage.error(response.data.msg)
    }
}

function error(err) {
    if (err.response) {
        // 请求已发出，但服务器响应的状态码不在 2xx 范围内
        ElMessage.error("服务器无响应")
    } else if (err.request) {
        // 请求已发出，但没有收到任何响应
        ElMessage.error("服务器无响应")
    } else {
        // 在设置请求时触发了一个错误
    }
}