<script setup>
import { ref, onMounted } from 'vue'
import { useScriptTag } from '@vueuse/core'
import BMap from "core-js/internals/map-helpers";

useScriptTag(
    'https://api.map.baidu.com/api?v=3.0&ak=BAP5HQYySQ0LKooPRaFtrHhDGi5csxGX',
    // on script tag loaded.
    (el, HTMLScriptElement) => {
      // do something
    },
)

const baiduRef = ref()
const map = ref()
const point = ref()

function initMap (lng = 116.405725, lat = 39.935362) {
  map.value = new BMap.Map(baiduRef.value);
  point.value = new BMap.Point(lng, lat);

  map.value.centerAndZoom(point.value, 15);
  map.value.enableScrollWheelZoom(true); //滚轮缩放
}

onMounted(() => {
  initMap()
})

</script>

<template>
  <div class="homebox">
    <div class="head">

      <div class="icont">
        <p class="p"> sdf</p>
        <h1 class="h1">智慧照明综合管理平台</h1>
        <button class="btn" @click="goTab">控制台</button>
        <button class="btn2">设置</button>
      </div>
    </div>
    <-- 设置放置地图的ref -->
    <div class="mapp" ref="baiduRef"></div>
    <div class="content"></div>
  </div>
</template>

<style scoped>

</style>