<!--租房页面-->
<template>
  <div class="common-layout">
    <el-container>
      <!--布局容器-共同的头部-->
      <el-header class="header" >
        <!--头部第一层-->
        <div class="header-div">
          <ul class="header-menu1">
            <li class="text"><a :href="BASE_URL" style="text-decoration: none;">首页</a></li>
            <li class="text"><a :href="BASE_URL+'/rah'" style="text-decoration: none;">租房</a></li>
            <li class="text"><a :href="BASE_URL" style="text-decoration: none;">发布房源</a></li>
            <li class="text"><a :href="BASE_URL" style="text-decoration: none;">下载APP</a></li>
            <li class="text"><a :href="BASE_URL" style="text-decoration: none;">房客网开放平台</a></li>
            <li class="text" style="float: right;cursor: pointer;"><a style="text-decoration: none;" @click="parentMethod(4)">注册</a></li>
            <li class="text" style="float: right;cursor: pointer;"><a style="text-decoration: none;" @click="parentMethod(2)">登录</a></li>
          </ul>
        </div>
        <H_login @dataFromChild="handleDataFromChild" :dialogVisible="dialogVisible" :clickToSwitchIn="clickToSwitchAll" ref="childRef"/>
        <!--头部第二层-->
        <div class="header-bg2">
          <div class="header-bg2-div">
            <img class="logo" src="@/assets/logo-header.png">
            <div class="text">
              <a :href="BASE_URL+'/rah/'" id="1" class="text-1">首页</a>
              <a :href="BASE_URL+'/rah/whole'" id="2" class="text-1">整租</a>
              <a :href="BASE_URL+'/rah/share'" id="3" class="text-1">合租</a>
            </div>
          </div>
        </div>
        <!--头部第三层-->

        <div class="header-bg3" >
          <div class="header-bg3-main">
            <div class="header-bg3-main-father">
              <input class="header-bg3-main-father-input" type="text" placeholder="请输入区域、商圈或小区名开始找房">
              <el-icon class="header-bg3-main-father-icon"><Search /></el-icon>
            </div>
          </div>
        </div>
      </el-header>
      <!--中间可变区域-->
      <el-main>
        <router-view/>
      </el-main>
      <!--布局容器-共同的页脚-->
      <el-footer>尾部</el-footer>
    </el-container>
  </div>
</template>

<script>
import {H_login} from "@/components";
export default {
  components: {H_login},
  data() {
    return {
      dialogVisible: false,   //默认为隐藏 (false)
      clickToSwitchAll : {id:'2'} //初始化打开页面为验证码登录页
    }
  },
  methods: {
    parentMethod(a) { //按钮点击事件处理
      this.dialogVisible=true;  //显示登录组件
      this.clickToSwitchAll.id = a;  //根据 a 的值改变打开的页面
    },
    handleDataFromChild(childData) { //用来返回给页面dialogVisible的值不必理会，直接复制
      this.dialogVisible=childData;
    },
  }
}
</script>

<style scoped>
*{
  margin: 0;
  padding: 0;
}
.header-bg3{
  width: 1150px;
  margin: 0 auto;
}
.header-bg3-main{
  display: inline-block;
  width: 710px;
  line-height: 46px;
  font-size: 14px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 1px 2px -1px rgba(0,0,0,.2);
}
.header-bg3-main-father-input{
  width: 668px;
  padding: 16px 23px 17px;
  box-sizing: border-box;
  border-width: 0;
  font-size: 13px;
  background-color: transparent;
  outline: none;
}
.header-bg3-main-father-icon{
  width: 38px;
  height: 38px;
  margin-top: 3px;
  margin-left: 4px;
  color: rgba(0,0,0,0.3);
  text-align: center;
  cursor: pointer;
}
.header {
  width: 100%;
  height: 200px;
  background-color: #f5f5f6;
  padding: 0;

}

.header-div{
  width: 100%;
  background-color: #303035;
}


/*头部第一层样式设计*/
.header-menu1 {
  width: 1150px;
  height: 30px;
  margin: 0 auto;
  list-style: none;
}

.header-menu1>.text a{
  float: left;
  color: gray;
  font-size: 12px;
  line-height: 30px;
  margin-right: 10px;
  padding-right: 10px;
}

.header-menu1>.text a:hover{
  color: white;
}

/*头部第二层样式设计*/
.header-bg2{
  width: 100%;
  display: flex;
}
.header-bg2-div{
  padding: 2rem 0;
  width: 1150px;
  margin: 0 auto;
}

.header-bg2 .logo{
  height: 30px;
  float: left;
  margin-right: 3rem;
}

.header-bg2 .text{
  line-height: 30px;
  font-size: 18px;
}
.header-bg2 .text>a{
  text-decoration: none;
  margin-right: 2rem;
  color: #000000;
  transition-duration: 0.2s;
}

.header-bg2 .text>a:hover{
  color: #3072f6;
}
</style>