import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//---------------------结束-------------------
//---------------------高德地图组件-------------
import VueAMap, {initAMapApiLoader} from '@vuemap/vue-amap';
import VueAMapLoca from '@vuemap/vue-amap-loca';
import VueAMapExtra from '@vuemap/vue-amap-extra';
import '@vuemap/vue-amap/dist/style.css'
initAMapApiLoader({
    key: '6e7809be5d7f2c50765d006c1c6093c4',
    securityJsCode: 'f4d84e566a844d3eab81909715785b44', // 新版key需要配合安全密钥使用
    plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
    Loca:{
     version: '2.0.0'
    }
})


//---------------------结束-------------------
//---------------------百度地图组件-------------
import Vue3BaiduMapGL from 'vue3-baidu-map-gl'
import baiduMap from 'vue3-baidu-map-gl'
//--------------------------------------------
//1.引入Element框架相关内容
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
//4.引入EL图标相关内容 https://element-plus.org/zh-CN/component/icon.html#注册所有图标
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}



// 将 EventBus 添加到 Vue 的原型上，这样可以在任何组件中通过 this.$eventBus 访问它
const MICRO_BLOG = 'http://localhost:8080';
// const BASE_URL = 'https://api.fivemhy.cn';
const BASE_URL = 'http://localhost';
//在window中添加的都是全局属性 这样在JS代码中就可以使用这个值了
window.MICRO_BLOG = MICRO_BLOG;
window.BASE_URL = BASE_URL;
app.config.globalProperties.BASE_URL = BASE_URL;
app.config.globalProperties.MICRO_BLOG = MICRO_BLOG;
//3. .use(ElementPlus) 引入ElementPlus组件库
app
    .use(baiduMap, {
    ak: 'BAP5HQYySQ0LKooPRaFtrHhDGi5csxGX',
    plugins: ['TrackAnimation']
})
    .use(Vue3BaiduMapGL)
    .use(ElementPlus, {
    locale: zhCn,
})
    .use(store)
    .use(router)
    .use(VueAMap)
    .use(VueAMapLoca)
    .use(VueAMapExtra)
    .mount('#app')



//2. createApp(App)创建一个vue实例  .use(store)添加全局存储vuex  .use(router)添加路由router
// mount挂载，将前面拿出来的App.vue中的内容放到/public/index.html页面中
//createApp(App).use(store).use(router).mount('#app')

// import './assets/global.css'
import './assets/icons/iconfont/iconfont.css'
// import PaginationTable from './components/PaginationTable/Table.vue'


