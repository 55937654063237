<script setup>
import {ref, onMounted,watch,computed} from "vue";
import { useStore } from 'vuex';
const user = ref({id: '', username: '', imgUrl: ''});
const store = useStore();

const loading = ref(true);
const isLoggedInComputed = computed(() => store.state.isLoggedIn);

const setLoading = () => {
  loading.value = true
    setTimeout(() => {
      if (!isLoggedInComputed.value) {
        return;
      }
      loading.value = false
    }, 800)
};


onMounted(() => {
  if (isLoggedInComputed.value){
    setLoading()
  }else {
    console.log("未登录")
  }
})


//监听isLoggedInComputed的值用watch
watch(
    () => store.state.isLoggedIn, // 观察的源
    (newValue, oldValue) => {
      // 当观察到的值变化时执行的回调函数
      // 注意：这里我们其实不需要更新 isLoggedIn 的值，
      // 因为我们只是在模板中直接使用 store.state.isLoggedIn 或将其封装在一个 computed 中
      // 但为了演示，我们还是更新了它
      isLoggedInComputed.value = newValue;
      console.log(isLoggedInComputed.value)
      setLoading()
      // 你也可以在这里执行其他逻辑，比如发送请求、更改其他状态等

    },
    {
      immediate: false, // 在组件挂载时立即以当前的源值触发回调
      deep: false, // 是否深度监听源对象的变化（对于对象或数组有用，这里不需要）
    }
);




</script>

<template>
  <el-skeleton :loading="loading" animated :throttle="0">
    <template #template>
      <el-card class="h_margin_bottom">
        <el-row>
          <el-col :span="24" class="h_margin_bottom" style="text-align: center">
            <el-skeleton-item variant="circle" style="width: 80px; height: 80px;"/>
          </el-col>
          <el-col :span="24" class="lineH-el-text h_margin_bottom" style="text-align: center">
            <el-skeleton-item variant="h3" style="width: 50%"/>
          </el-col>
          <el-col :span="24" class="lineH-el-text h_margin_bottom" style="text-align: center">
            <el-skeleton-item variant="h3" style="width: 50%"/>
          </el-col>
        </el-row>
        <el-divider/>
        <el-skeleton-item variant="h3" style="width: 80%"/>
        <el-skeleton-item variant="h3" style="width: 60%"/>
        <el-skeleton-item variant="h3" style="width: 70%"/>
      </el-card>
    </template>

    <template #default>
      <el-card class="h_margin_bottom">
        <div v-if="isLoggedInComputed" class="card-header">
          <el-row>
            <el-col :span="24" class="h_margin_bottom" style="text-align: center">
              <el-avatar style="margin: 0 auto;" :size="80" src=""></el-avatar>
<!--              BASE_URL+user.imgUrl-->
            </el-col>
            <el-col :span="24" class="lineH-el-text h_margin_bottom" style="text-align: center">
              <el-text style="font-size: 20px">{{ "user.username" }}</el-text>
<!--              user.username-->
            </el-col>
            <el-col :span="24" class="lineH-el-text h_margin_bottom" style="text-align: center">
              <el-text style="font-size: 12px">{{ "user.autograph" }}</el-text>
<!--              user.autograph-->
            </el-col>
          </el-row>
        </div>
        <el-divider/>
        <div class="text item">获得点赞 {{ "article.like" }}</div>
        <div class="text item">文章被阅读 {{ "article.reading" }}</div>
        <div class="text item">下载次数 {{ "article.download" }}</div>
      </el-card>
    </template>
  </el-skeleton>

</template>

<style scoped>
.h_margin_bottom{
  margin-bottom: 1rem;
}
</style>