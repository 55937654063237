<script>
import axios from 'axios';
import {CommentForum} from '@/components';


export default {
  components: {
    CommentForum
  },
  data() {
    return {
      msg: null,
      code: null,
      tagCod: null,
      article: {
        uid: null,
        wid: null,
        userName: null,
        avatar: null,
        title: null,
        version: null,
        tagCod: null,
        like: null,
        reading: null,
        time: null,
        autograph: null,
        download: null,
        share: null,
        content: null
      },
    };
  },
  created: function () {
    const wid = this.$route.params.id; // 确保 wid 在这里可用
    axios.get(`http://localhost:8080/v1/weibo/selectById?id=${wid}`)
        .then((response) => {
          this.code = response.data.code;
          if (this.code == 2002) {
            window.location.href = "../";
          }
          this.msg = response.data.msg;
          this.article = response.data.data;
          var contentContainer = document.getElementById('content-container');
          var div = document.createElement('div');
          div.innerHTML = this.article.content;
          contentContainer.appendChild(div);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    axios.get('http://localhost:8080/v1/weibo/queryTags')
        .then((response) => {
          this.tagCod = response.data.data;
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });

  }
};

</script>
<script setup>
import {UserFilled} from '@element-plus/icons-vue';
import {CardArticle} from "@/components/index";
import {ref} from "vue";
import axios from "axios";
import qs from "qs";
import {ElMessage} from "element-plus";
import {useRoute} from 'vue-router';
import router from "@/router";


const user = ref({id: "", username: ""});

axios.interceptors.request.use(config => {
  const token = localStorage.token ? localStorage.token : false;
  return config;
}, error => {
  return Promise.reject(error);
});


const route = useRoute();
const postComments = ref(null);
const comments = ref(null);
const weiboId = route.params.id;

const tokenObject = localStorage.token ? localStorage.token : false;
const token = tokenObject.token;
const post = () => {

  if (comments.value == null) {
    ElMessage.error("请输入评论内容");
    return
  }
  if (token == null) {
    ElMessage.error("未登录");
    return
  }
  const postComments2116 = ref({userId: ""});
  postComments2116.value.content = comments.value;
  postComments2116.value.userId = user.value.id;
  postComments2116.value.weiboId = weiboId;  // 确保 wid 在这里可用;
  postComments2116.value.token = token;
  let data = qs.stringify(postComments2116.value);
  axios.post("http://localhost:8080/v1/comment/insert", data)
      .then((response) => {
        if (response.data.code == 2001) {
          ElMessage.success(response.data.msg);
          location.reload();
        }
        if (response.data.code == 1000) {
          ElMessage.error(response.data.msg);
          router.push('/login');
        }
      })
}

</script>


<template>
  <el-row :gutter="10" class="h_margin_top">
    <el-col :sm="24" :lg="18">
      <el-card class="box-card h_margin_bottom">
        <div class="h_margin_bottom">
          <el-text style="font-size: 30px;">
            {{ article.title }}
          </el-text>
        </div>
        <div class="card-header-tagdiv h_margin_bottom">
          <span v-if="article.tagCod!=null" v-for="m in article.tagCod.split(',')">
            <span v-for="n in tagCod">
              <router-link to="./">
                <el-text v-if="n.id == m">
                  <el-tag :type="n.colour" :effect="n.effect" class="h_margin_right">
                    {{ n.content }}
                  </el-tag>
                </el-text>
              </router-link>
            </span>
          </span>
          <el-text class="mx-1" type="info">{{ article.time }} · 阅读 {{ article.reading }}</el-text>
        </div>
        <div class="text item">
          <el-tag type="warning" size="large" style="width: 100%;justify-content: left" class="h_margin_bottom">
            {{ article.version }}
          </el-tag>
        </div>
        <div class="text item" id="content-container">
        </div>
      </el-card>
      <el-card class="h_margin_bottom">
        <el-row :gutter="10">
          <el-text class="h_margin_left h_margin_bottom" style="font-size: 16px;">
            <el-icon class="h_margin_right">
              <CollectionTag/>
            </el-icon>
            评论
          </el-text>
          <el-divider/>
          <el-col :span="24">
            <el-form ref="form" :model="postComments">
              <div class="card-header-tagdiv h_margin_bottom">
                <div style="float: left" class="h_margin_right">
                  <div v-if="article.avatar!=null">
                    <el-avatar :icon="UserFilled" :src="article.avatar"/>
                  </div>
                  <div v-if="article.avatar==null">
                    <el-avatar :icon="UserFilled"/>
                  </div>
                </div>
                <div style="float: right;width: calc(100% - 48px);">
                  <el-input
                      v-model="comments"
                      :rows="4"
                      type="textarea"
                      placeholder="请输入评论"
                      style="width: 100%"
                      resize="none"
                  />
                </div>
              </div>
              <div style="float: right" class="h_margin_top h_margin_bottom">
                <el-button type="primary" @click="post">发表评论</el-button>
              </div>
            </el-form>
          </el-col>

          <el-col :span="24" class="h_margin_bottom">
            <el-text style="font-size: 16px;">
              全部评论 {{ 1 }}
            </el-text>
          </el-col>
          <el-col>
            <CommentForum/>
          </el-col>
        </el-row>
      </el-card>
    </el-col>
    <el-col :sm="24" :lg="6" class="affix-col">

      <el-card class="box-card h_margin_bottom">
        <div class="card-header">
          <el-row :span="24" style="height: 60px;">
            <el-col :span="6" style="height: 60px; line-height: 45px;">
              <div v-if="article.avatar!=null">
                <el-avatar :icon="UserFilled" :src="article.avatar"/>
              </div>
              <div v-if="article.avatar==null">
                <el-avatar :icon="UserFilled"/>
              </div>
            </el-col>
            <el-col :span="18" class="lineH-el-text">
              <ul>
                <li>
                  <el-text style="font-size: 20px">{{ article.userName }}</el-text>
                </li>
                <li>
                  <el-text style="font-size: 12px">{{ article.autograph }}</el-text>
                </li>
              </ul>
            </el-col>
          </el-row>
        </div>
        <div>
          <el-button type="primary" style="border-radius: 8px;width: 100%">关注</el-button>
        </div>
        <el-divider/>
        <div class="text item">获得点赞 {{ article.like }}</div>
        <div class="text item">文章被阅读 {{ article.reading }}</div>
        <div class="text item">下载次数 {{ article.download }}</div>
      </el-card>
      <CardArticle/>

    </el-col>
  </el-row>
</template>

<style scoped>
.h_margin_bottom{
  margin-bottom: 0.5rem;
}

.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.h_margin_bottom1 {
  margin-bottom: 1rem;
}

.affix-col {
  height: 100%;
}

.box-card {
  padding: 1.66rem;
}

.lineH-el-text > ul li {
  margin-left: 0;
  list-style: none;
}

span.el-text.h_margin_left.h_margin_bottom {
  margin-bottom: 0.5rem;
}

.lineH-el-text > ul {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}


</style>

