<script setup>

import {ref} from "vue";

const backgroundPosition = ref(1)
</script>

<template>
  <el-card class="h_margin_bottom">
    <div class="title-theme mb10">签到排行</div>
    <a-list :bordered="false">
      <el-skeleton
          :loading="false"
          animated
          :throttle="0">
        <template #template>
          <div v-for="(m,index) in 10" key="index" style="width: 100%;">
            <a-list-item>
              <div style="float: right;width: 100%;height: 2rem;line-height: 2.8rem">
                <el-skeleton-item variant="h3" style="width: 100%"/>
              </div>
            </a-list-item>
          </div>
        </template>
        <template #default>

          <div style="border-radius: 0.5rem;display: flex;width: 100%;height: 2.5rem;background-image: url('/qiandaobj.png');margin-top: 0.5rem;position: relative;">
            <div style="width: 50%;display: flex;justify-content: center;align-items: center;" @click="backgroundPosition=1">
              <span style="color: #fff;font-size: 14px;padding: 0.2rem 0.6rem">最近签到</span>
            </div>
            <div style="width: 50%;display: flex;justify-content: center;align-items: center;color: #fff;" @click="backgroundPosition=2">
              <span style="color: #fff;font-size: 14px;padding: 0.2rem 0.6rem">连续签到</span>
            </div>
            <div :class=" backgroundPosition===1?'backgroundPosition position1 ':'backgroundPosition position2 '"/>
          </div>
          <div v-for="(m,index) in 6" key="index" style="width: 100%;" v-if="backgroundPosition===2">
            <a :href="'http://localhost/bbs/h/'+m.id" style="display: flex;align-items: center; margin: 1rem 0">
              <div style="width: 36px;height: 36px;" >
                <el-avatar shape="square" :size="36" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" />
              </div>
              <div style="height: 2rem;display: flex;flex-direction: column;margin: auto auto auto 1rem">
                <span style="color: rgba(0,0,0,1);font-size: 14px">晚风</span>
                <span style="color: rgba(120,120,120,1);font-size: 12px">6月16日</span>
              </div>
              <div>
                <span style="font-size: 14px;color: rgba(80,80,80)">连续1天</span>
              </div>
            </a>
          </div>
          <div v-for="(m,index) in 6" style="width: 100%;" v-else>
            <a :href="'http://localhost/bbs/h/'+m.id" style="display: flex;align-items: center; margin: 1rem 0">
              <div style="width: 36px;height: 36px;" >
                <el-avatar shape="square" :size="36" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" />
              </div>
              <div style="height: 2rem;display: flex;flex-direction: column;margin: auto auto auto 1rem">
                <span style="color: rgba(0,0,0,1);font-size: 14px">晚风</span>
                <span style="color: rgba(120,120,120,1);font-size: 12px">6月16日</span>
              </div>
              <div style="background: rgba(240,240,240);border-radius: 0.3rem;padding: 0.2rem 0.5rem;display: flex;align-items: center;">
                <el-icon style="font-size: 14px;color: rgba(80,80,80)" ><Coin /></el-icon>
                <span style="font-size: 14px;color: rgba(80,80,80)">90</span>
              </div>
            </a>
          </div>
        </template>
      </el-skeleton>
    </a-list>
  </el-card>
</template>

<style scoped>
.backgroundPosition{
  position: absolute;
  width: 76px;
  height:27px;
  border-radius: 1rem;
  background: rgba(240,240,240,0.4);
  top:6px;
  left: 15px;
  transition: 0.5s;
}
.position1{
  left: 15px;
}
.position2{
  left: 122px;
}
.title-theme {
  position: relative;
  padding-left: 1.2em;
  font-size: 15px;
}

.title-theme:before {
  position: absolute;
  content: '';
  width: 4px;
  background: #00a2e3;
  top: 10%;
  left: 2px;
  bottom: 10%;
  border-radius: 5px;
  box-shadow: 1px 1px 3px -1px #00a2e3;
}

.el-popper.is-customized {
  /* Set padding to ensure the height is 32px */
  padding: 6px 12px;
  background: linear-gradient(90deg, rgb(159, 229, 151), rgb(204, 229, 129));
}

.el-popper.is-customized .el-popper__arrow::before {
  background: linear-gradient(45deg, #b2e68d, #bce689);
  right: 0;
}
</style>