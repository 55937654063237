<script setup>
// import {articleClassification} from "@/components/constants";
// http://localhost:8080/v1/weibo/selectIndexForum
import {onMounted, ref} from "vue";
import axios from "axios";
const articleClassification =ref();
const loading = ref(true);
const setLoading = () => {
  loading.value = true
  setTimeout(() => {
    if (articleClassification.value != null){
      loading.value = false
    }
  }, 800)
};



onMounted(() =>{
  setLoading()
  axios.get(MICRO_BLOG+'/v1/weibo/selectIndexForum')
      .then(response => {
        articleClassification.value = response.data.data;
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
})
</script>



<template>

  <el-skeleton
      :loading="loading"
      animated
      :throttle="0">
    <template #template>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-card class="h_margin_bottom">
            <div slot="header" class="clearfix">
              <router-link to="/" style="text-decoration: none;">
                <el-skeleton-item variant="h3" style="width: 200px"/>
              </router-link>

              <!--          <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
            </div>
            <el-divider />
            <el-row :gutter="20" v-for="(m,index) in 6" :key="index">
              <el-col :span="8" style="margin-bottom: 0.5rem">
                <el-link to="/" :underline="false">
                  <el-skeleton-item variant="circle" style="width: 64px; height: 64px"/>
                  <ul style="list-style: none;margin: 0 0 0 1rem;padding: 0">
                    <li>
                      <el-skeleton-item variant="h3" style="width: 100%"/>
                    </li>
                    <li>
                      <el-skeleton-item variant="h3" style="width: 80%"/>
                    </li>
                    <li>
                      <el-skeleton-item variant="h3" style="width: 200px"/>
                    </li>
                  </ul>
                </el-link>
              </el-col>
              <el-divider v-if="index < 5" border-style="dashed"/>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </template>
    <template #default>


  <el-row :gutter="10">
    <el-col :span="24" v-for="m in articleClassification">
      <el-card class="h_margin_bottom">
        <div slot="header" class="clearfix">
          <router-link :to="m.titleUrl" style="text-decoration: none;">
            <el-text class="clearfix-text" tag="b" size="large">{{m.title}}</el-text>
          </router-link>

<!--          <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
        </div>
        <el-divider />
        <el-row :gutter="20" v-for="(model,index) in m.model" :key="index" >
          <el-col :span="8" style="margin-bottom: 0.5rem">
            <el-link to="/" :underline="false">
              <el-image class="model-link-ul-li-image" :src="model.imageUrl"/>
              <ul style="list-style: none;margin: 0 0 0 1rem;padding: 0">
                <li>
                  <a :href="'../bbs/h/'+model.id">
                  <el-text class="model-link-ul-li-text" tag="b" size="large">
                    {{model.name}}
                  </el-text>
                  </a>
                </li>
                <li>
                  <el-text size="small">
                    主题: {{model.topics}}, 帖数: {{ model.posts }}
                  </el-text>
                </li>
                <li>
                  <el-text class="model-link-ul-li-text" size="small">
                    最后发表: {{ model.time }}
                  </el-text>
                </li>
              </ul>
            </el-link>
          </el-col>
          <el-divider v-if="index < m.model.length-1" border-style="dashed"/>
        </el-row>
      </el-card>
    </el-col>
  </el-row>

    </template>
  </el-skeleton>
</template>

<style scoped>
.clearfix-text{
  font-size: 20px;
  line-height: 40px;
  transition-duration: .3s;
}
.model-link-ul-li-text{
  transition-duration: .3s;
}
.model-link-ul-li-image{
  width: 4rem;
  float: left;
  transition-duration: .3s;
}

.model-link-ul-li-image:hover{
  transform: scale(1.1);
}
.model-link-ul-li-text:hover{
  text-shadow: 1px 1px 5px #a1a1a1;
}
.clearfix-text:hover{
  text-shadow: 1px 1px 5px #a1a1a1;
}

.h_margin_bottom{
  margin-bottom: 0.5rem;
}
</style>