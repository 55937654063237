import {ref} from "vue";
export const select = ref('');
export const searchForArticles = ref('');

export const classification = ref([
    {name: "首页", titleUrl: "/"},
    {name: "免费", titleUrl: "/"},
    {name: "付费", titleUrl: "/"},
])

export const ProductArr = ref([
    {
        id: 1,
        title: "森马牛仔裤女宽松慢跑裤运动风2022春季新款",
        price: 233,
        oldPrice: 598,
        url: "/imgs/a.jpg",
        saleCount: 2342,
        inventory: 3450206
    },
    {
        id: 2,
        title: "茵曼马甲连衣裙两件套春季新款娃娃领色织格长袖背心裙套装",
        price: 233,
        oldPrice: 598,
        url: "/imgs/b.jpg",
        saleCount: 2342,
        inventory: 7853
    },
    {
        id: 3,
        title: "雪中飞墨绿色短袖t恤女夏2022新款纯棉半袖打底体恤夏季",
        price: 233,
        oldPrice: 598,
        url: "/imgs/c.jpg",
        saleCount: 2342,
        inventory: 156
    },
    {
        id: 4,
        title: "【佟丽娅同款】鸭鸭明星同款羽绒服2021年冬季新款时尚",
        price: 233,
        oldPrice: 598,
        url: "/imgs/d.jpg",
        saleCount: 2342,
        inventory: 45306
    },
    {
        id: 5,
        title: "BEASTER小恶魔鬼脸明星同款夹克毛绒保暖加厚字母印花",
        price: 233,
        oldPrice: 598,
        url: "/imgs/e.jpg",
        saleCount: 2342,
        inventory: 12385206
    },
    {
        id: 6,
        title: "香影毛呢外套女中长款2021年冬季新款气质韩版娃娃领",
        price: 233,
        oldPrice: 598,
        url: "/imgs/f.jpg",
        saleCount: 2342,
        inventory: 378206
    },
    {
        id: 7,
        title: "SEMIR森马商场同款打底针织毛衣纯色高领新品显瘦",
        price: 233,
        oldPrice: 598,
        url: "/imgs/g.jpg",
        saleCount: 2342,
        inventory: 2453206
    },
    {
        id: 8,
        title: "美特斯邦威女MTEE 贺岁系列中长款风衣736598",
        price: 233,
        oldPrice: 598,
        url: "/imgs/h.jpg",
        saleCount: 2342,
        inventory: 4536
    },
    {
        id: 9,
        title: "imone2021秋款黑色小西装外套女韩版学生宽松学",
        price: 233,
        oldPrice: 598,
        url: "/imgs/i.jpg",
        saleCount: 2342,
        inventory: 204536
    },
    {
        id: 10,
        title: "BEASTER 小恶魔明星同款保暖长袖街头潮流连帽卫",
        price: 233,
        oldPrice: 598,
        url: "/imgs/j.jpg",
        saleCount: 2342,
        inventory: 202348
    },
    {
        id: 11,
        title: "憨厚皇后100%绵羊皮2021秋海宁真皮皮衣女长款修",
        price: 233,
        oldPrice: 598,
        url: "/imgs/k.jpg",
        saleCount: 2342,
        inventory: 20783
    },
    {
        id: 12,
        title: "美特斯邦威高腰牛仔裤女宽松小脚新款春秋彩色",
        price: 233,
        oldPrice: 598,
        url: "/imgs/a.jpg",
        saleCount: 2342,
        inventory: 2004563
    }]);

