<script setup>

</script>

<template>
  <div class="classificationAnnouncementCard">
    <div style="height: 20px;">
      <div style="float: left">
        <span>
          免费资源
        </span>
        <span>
          今日：8 | 主题：805 | 排名：1
        </span>
      </div>
      <div style="float: right">
        <span>
          收藏
        </span>
        <span>
          订阅
        </span>
      </div>
    </div>
    <div>
      <div align="center"><font size="6"><font color="Blue">技术无价-分享快乐</font></font></div><br>
      <br>
      <br>
      <br>
      &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; <font size="4"><font color="RoyalBlue">FiveM是一款GTA5联机平台，可以在这个平台上用自己制作的服务端开服，可以让多人联机玩GTA5的各种MOD，对比GTAVOL更具有创造性、自由性。在FiveM里，你可以当一名警察，开着警车，在洛圣都里追捕罪犯。相反，你也可以当个亡命之徒，到处寻求机会以获得更大的不义之财。或者你也创建自由服务器，装上很多修改器，总之，在FiveM里总会有一个服务器玩法适合你，如果对FiveM感兴趣，欢迎来本论坛查阅相关资料。至于为什么免费，因为我觉得国内的FIVEM没有很好的普及，故做此决定。 </font></font><br>
      =======<br>
      <font size="5"><font color="blue">【教程】&gt;&gt;</font></font><font size="5"><a href="https://www.gtagm.cn/forum.php?mod=viewthread&amp;tid=4" target="_blank">服务端架设</a></font>----<font size="5"><a href="https://www.gtagm.cn/forum.php?mod=viewthread&amp;tid=143" target="_blank">修改插件按键</a></font>----<font size="5"><a href="https://www.gtagm.cn/forum.php?mod=viewthread&amp;tid=560" target="_blank">按键编号表</a></font>----<font size="5"><a href="https://www.gtagm.cn/forum.php?mod=viewthread&amp;tid=163" target="_blank">载具代码</a></font>----<font size="5"><a href="https://www.gtagm.cn/forum.php?mod=viewthread&amp;tid=57" target="_blank">设置管理员</a></font>----<font size="5"><a href="https://www.gtagm.cn/forum.php?mod=viewthread&amp;tid=259" target="_blank">车店加车</a></font>----<font size="5"><a href="https://www.gtagm.cn/forum.php?mod=viewthread&amp;tid=245" target="_blank">地图编辑器</a></font>----<font size="5"><a href="https://www.gtagm.cn/forum.php?mod=viewthread&amp;tid=107" target="_blank">数据库软件</a></font>

    </div>
  </div>
</template>

<style scoped>
.classificationAnnouncementCard{
  background-color: rgba(255,255,255);
  margin-bottom: 1rem;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.4rem;
}
</style>