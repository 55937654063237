<template>
  <!--  <BMap-->
  <!--      enableScrollWheelZoom-->
  <!--      ref="map"-->
  <!--      @initd="get"-->
  <!--      :center="location.point || undefined"-->
  <!--      @click="openTheCard(0)"-->
  <!--      height="100vh"-->
  <!--      :zoom="zoom"-->
  <!--      :minZoom="3"-->
  <!--      mapType="BMAP_NORMAL_MAP"-->
  <!--      :enableDragging="true"-->
  <!--      :enableInertialDragging="true"-->
  <!--      :enableScrollWheelZoom="true"-->
  <!--      :enableContinuousZoom="true"-->
  <!--      :enableDoubleClickZoom="true"-->
  <!--      :enableKeyboard="true"-->
  <!--      :enablePinchToZoom="true"-->
  <!--      style="min-width: 970px"-->
  <!--  >-->
  <!--    <BCircle stroke-color="#000" stroke-opacity="1" stroke-style="solid" :center="{ lat: 29.885474, lng: 121.538476 }" :radius="300"  :geodesic="false"/>-->

  <!--    &lt;!&ndash;    BMAP_EARTH_MAP&ndash;&gt;-->
  <!--    <BLocation-->
  <!--        anchor="BMAP_ANCHOR_TOP_RIGHT"-->
  <!--        :offset="{ x: 10, y: 70 }"-->
  <!--    />-->

  <!--    <BControl anchor="BMAP_ANCHOR_BOTTOM_RIGHT"-->
  <!--              style="padding: 10px" :offset="{ x: 0, y: 0 }">-->
  <!--      <button class="BControl-button" @click="handleZoomIn">-->
  <!--        <el-icon>-->
  <!--          <Plus/>-->
  <!--        </el-icon>-->
  <!--      </button>-->
  <!--      <button class="BControl-button" @click="handleZoomOut">-->
  <!--        <el-icon>-->
  <!--          <Minus/>-->
  <!--        </el-icon>-->
  <!--      </button>-->
  <!--    </BControl>-->

  <!--    <BLabel-->
  <!--        content="阿星的Home"-->
  <!--        :position="{-->
  <!--          lng: 121.539041,-->
  <!--          lat: 29.886433-->
  <!--        }"-->
  <!--        :style="{-->
  <!--        color: '#fff',-->
  <!--        backgroundColor: '#f90',-->
  <!--        border: 'none',-->
  <!--        borderRadius: '3px',-->
  <!--        padding: '5px 10px',-->
  <!--        fontSize: '16px'-->
  <!--      }"-->
  <!--    />-->


  <!--    <BControl style="padding: 10px" :offset="{ x: 0, y: 0 }">-->
  <!--      <div class="info">-->
  <!--        <div class="input-item">-->
  <!--          <div class="input-item-div">-->
  <!--            <img src="/favicon.ico" width="24" alt="">-->
  <!--          </div>-->
  <!--          <div class="input-item-div">-->
  <!--            <div class="input-item-div-anniu">-->
  <!--              <button class="input-item-div-anniu-button" @click="openTheCard(1)">-->
  <!--                宁波-->
  <!--                <el-icon size="12" style="color: rgb(185,185,185)">-->
  <!--                  <CaretBottom/>-->
  <!--                </el-icon>-->
  <!--              </button>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--          <div class="input-item-div">-->
  <!--            <div class="input-item-div-anniu">-->
  <!--              <button class="input-item-div-anniu-button" @click="openTheCard(2)">-->
  <!--                二手房-->
  <!--                <el-icon size="12" style="color: rgb(185,185,185)">-->
  <!--                  <CaretBottom/>-->
  <!--                </el-icon>-->
  <!--              </button>-->
  <!--            </div>-->

  <!--          </div>-->
  <!--          <div class="input-item-div">-->
  <!--            <el-divider style="height: 20px" direction="vertical"/>-->
  <!--          </div>-->
  <!--          <div class="input-item-div input-item-div-frame" style="height: 24px">-->
  <!--            <div class="input-item-div-div">-->
  <!--              <input v-model="input" class="input-item-div-div-input" style="outline: none;" type="text"-->
  <!--                     placeholder="请输入您的目标地点">-->
  <!--            </div>-->
  <!--            <div class="input-item-div-div">-->
  <!--              <button class="input-item-div-div-button">-->
  <!--                <el-icon>-->
  <!--                  <Search/>-->
  <!--                </el-icon>-->
  <!--              </button>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </BControl>-->

  <!--    <BControl style="padding: 10px" :offset="{ x: 470, y: 0 }">-->
  <!--      <div class="info">-->
  <!--        <div class="input-item">-->
  <!--          <div class="input-item-div-right">-->
  <!--            <div class="input-item-div-anniu">-->
  <!--              <button :class="'input-item-div-anniu-button '+(openTheCardAll.number === 3?'newStyle':null)" @click="openTheCard(3)">-->
  <!--                价格-->
  <!--                <el-icon size="12" style="color: rgb(185,185,185)">-->
  <!--                  <CaretBottom/>-->
  <!--                </el-icon>-->
  <!--              </button>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--          <div class="input-item-div-right">-->
  <!--            <div class="input-item-div-anniu">-->
  <!--              <button :class="'input-item-div-anniu-button '+(openTheCardAll.number === 4?'newStyle':null)" @click="openTheCard(4)">-->
  <!--                房型-->
  <!--                <el-icon size="12" style="color: rgb(185,185,185)">-->
  <!--                  <CaretBottom/>-->
  <!--                </el-icon>-->
  <!--              </button>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--          <div class="input-item-div-right">-->
  <!--            <div class="input-item-div-anniu">-->
  <!--              <button :class="'input-item-div-anniu-button '+(openTheCardAll.number === 5?'newStyle':null)" @click="openTheCard(5)">-->
  <!--                面积-->
  <!--                <el-icon size="12" style="color: rgb(185,185,185)">-->
  <!--                  <CaretBottom/>-->
  <!--                </el-icon>-->
  <!--              </button>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--          <div class="input-item-div-right">-->
  <!--            <div class="input-item-div-anniu">-->
  <!--              <button :class="'input-item-div-anniu-button '+(openTheCardAll.number === 6?'newStyle':null)" @click="openTheCard(6)">-->
  <!--                更多-->
  <!--                <el-icon size="12" style="color: rgb(185,185,185)">-->
  <!--                  <CaretBottom/>-->
  <!--                </el-icon>-->
  <!--              </button>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </BControl>-->
  <!--    <BControl v-if="openTheCardAll.number > 2 || openTheCardAll.number ==0" style="padding: 10px" :offset="{ x: 0, y: 60 }">-->
  <!--      <div class="info">-->
  <!--        <div style="width: 410px">-->
  <!--          <span style="line-height: 50px;">可视区域内为您找到7919套房子</span>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </BControl>-->
  <!--    <BControl  v-if="openTheCardAll.number == 1" style="padding: 10px" :offset="{ x: 0, y: 60 }">-->
  <!--      <div class="info" style="width: 410px">-->
  <!--        <div id="hover-click-after">-->
  <!--          <el-descriptions column="4" title="热门城市">-->
  <!--            <el-descriptions-item>-->
  <!--              北京-->
  <!--            </el-descriptions-item>-->
  <!--            <el-descriptions-item>-->
  <!--              上海-->
  <!--            </el-descriptions-item>-->
  <!--            <el-descriptions-item>-->
  <!--              广州-->
  <!--            </el-descriptions-item>-->
  <!--            <el-descriptions-item>-->
  <!--              深圳-->
  <!--            </el-descriptions-item>-->
  <!--            <el-descriptions-item>-->
  <!--              成都-->
  <!--            </el-descriptions-item>-->
  <!--          </el-descriptions>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </BControl>-->
  <!--    <BControl v-if="openTheCardAll.number == 2" style="padding: 10px" :offset="{ x: 100, y: 60 }">-->
  <!--      <div class="info" style="min-width: 100px;padding-left: 2px;padding-right: 2px ">-->
  <!--        <div id="hover-click-after" >-->
  <!--          <button class="hover-click-after-button">二手房</button>-->
  <!--          <button class="hover-click-after-button">租房</button>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </BControl>-->
  <!--    <BControl v-if="openTheCardAll.number > 2" style="padding: 10px;width: 26rem" :offset="{ x: 470, y: 60 }">-->
  <!--      <div class="info" style="padding: 1.5rem">-->
  <!--        <div id="hover-click-after">-->
  <!--          <div v-if="openTheCardAll.number == 3">-->
  <!--            <el-descriptions column="4" title="价格（万）">-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                40以下-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                40-60-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                60-80-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                80-100-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                100-150-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                150-200-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                200-300-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                300以上-->
  <!--              </el-descriptions-item>-->
  <!--            </el-descriptions>-->
  <!--            <el-divider/>-->
  <!--            <div>-->
  <!--              <input type="number" class="hover-click-after-number">-->
  <!--              - -->
  <!--              <input type="number" class="hover-click-after-number">-->
  <!--              (万)-->
  <!--            </div>-->
  <!--          </div>-->
  <!--          <div v-if="openTheCardAll.number == 4">-->
  <!--            <el-descriptions column="4" title="房型">-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                一室-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                二室-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                三室-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                四室-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                五室-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                五室以上-->
  <!--              </el-descriptions-item>-->
  <!--            </el-descriptions>-->
  <!--          </div>-->
  <!--          <div v-if="openTheCardAll.number == 5">-->
  <!--            <el-descriptions column="4" title="面积（m²）">-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                50以下-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                50-70-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                70-90-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                90-120-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                120-150-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                150-200-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                200-300-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                300以上-->
  <!--              </el-descriptions-item>-->
  <!--            </el-descriptions>-->
  <!--            <el-divider/>-->
  <!--            <div>-->
  <!--              <input type="number" class="hover-click-after-number">-->
  <!--              - -->
  <!--              <input type="number" class="hover-click-after-number">-->
  <!--              (m²)-->
  <!--            </div>-->
  <!--          </div>-->
  <!--          <div v-if="openTheCardAll.number == 6">-->
  <!--            <el-descriptions column="4" title="房源特色">-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                必看好房-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                满五年-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                满两年-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                近地铁-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                VR房源-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                VR看装修-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                7日新上-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                随时看房-->
  <!--              </el-descriptions-item>-->
  <!--            </el-descriptions>-->

  <!--            <el-descriptions column="4" title="朝向">-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                南北-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                朝南-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                朝东-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                朝北-->
  <!--              </el-descriptions-item>-->
  <!--              <el-descriptions-item>-->
  <!--                <el-checkbox v-model="checked1" size="large"/>-->
  <!--                朝西-->
  <!--              </el-descriptions-item>-->

  <!--              <el-descriptions column="4" title="楼层">-->
  <!--                <el-descriptions-item>-->
  <!--                  <el-checkbox v-model="checked1" size="large"/>-->
  <!--                  低楼层-->
  <!--                </el-descriptions-item>-->
  <!--                <el-descriptions-item>-->
  <!--                  <el-checkbox v-model="checked1" size="large"/>-->
  <!--                  中楼层-->
  <!--                </el-descriptions-item>-->
  <!--                <el-descriptions-item>-->
  <!--                  <el-checkbox v-model="checked1" size="large"/>-->
  <!--                  高楼层-->
  <!--                </el-descriptions-item>-->
  <!--              </el-descriptions>-->
  <!--            </el-descriptions>-->


  <!--          </div>-->
  <!--          <div style="margin: 1rem 0;height: 0.2rem">-->
  <!--            <button class="hover-click-after-div-button" style="float: left">-->
  <!--              重置-->
  <!--            </button>-->
  <!--            <button class="hover-click-after-div-button" style="float: right;margin-left: 0.5rem">-->
  <!--              确定-->
  <!--            </button>-->
  <!--            <button class="hover-click-after-div-button" style="float: right">-->
  <!--              取消-->
  <!--            </button>-->
  <!--          </div>-->
  <!--        </div>-->

  <!--      </div>-->
  <!--    </BControl>-->
  <!--    <BControl style="padding: 10px" anchor="BMAP_ANCHOR_TOP_RIGHT" :offset="{ x: 0, y: 0 }">-->
  <!--      <div class="info" style="min-width: 0;">-->
  <!--        <div>-->
  <!--      <span style="line-height: 48px;">-->
  <!--        地铁找房 | 画圈找房-->
  <!--      </span>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </BControl>-->
  <!--  </BMap>-->
  <div class="map-container">
    <el-amap
        :center="center"
        :zoom="zoom"
        @click="openTheCard(0)"
    >
      <el-amap-control-tool-bar/>
      <el-amap-control-geolocation position="RT" :offset="[16,80]" borderRadius="7px"/>

      <el-amap-marker
          :position="[121.530257,29.881315]"
          :visible="true"
          :draggable="false"
      >
        <div
            style="padding: 5px 5px 0 10px;position: absolute;white-space: nowrap;background: rgba(48,114,246);color: #fff;border-radius: 1rem">
          冯鑫家|72套
          <div
              style="display: inline-block;padding: 1px 5px;background-color: #fff;white-space: nowrap;border-radius: 1rem;color: rgba(48,114,246)">
            1.6万
          </div>
          <div style="position:relative;bottom: -5px;width: 100%;display: flex;justify-content: center;">
            <div class="inverted-triangle"></div>
          </div>
        </div>
      </el-amap-marker>

      <el-amap-marker
          :position="[121.52336,29.870101]"
          :visible="true"
          :draggable="false"
      >
        <div
            style="padding: 5px 5px 0 10px;position: absolute;white-space: nowrap;background: rgba(48,114,246);color: #fff;border-radius: 1rem">
          付禹家|72套
          <div
              style="display: inline-block;padding: 1px 5px;background-color: #fff;white-space: nowrap;border-radius: 1rem;color: rgba(48,114,246)">
            1.6万
          </div>
          <div style="position:relative;bottom: -5px;width: 100%;display: flex;justify-content: center;">
            <div class="inverted-triangle"></div>
          </div>
        </div>
      </el-amap-marker>

      <el-amap-marker
          :position="[121.530506,29.882939]"
          :visible="true"
          :draggable="false"
      >
        <div
            style="padding: 5px 5px 0 10px;position: absolute;white-space: nowrap;background: rgba(48,114,246);color: #fff;border-radius: 1rem">
          汪新宇家|72套
          <div
              style="display: inline-block;padding: 1px 5px;background-color: #fff;white-space: nowrap;border-radius: 1rem;color: rgba(48,114,246)">
            1.6万
          </div>
          <div style="position:relative;bottom: -5px;width: 100%;display: flex;justify-content: center;">
            <div class="inverted-triangle"></div>
          </div>
        </div>
      </el-amap-marker>

    </el-amap>

    <div class="info" style="min-width: 0;position: absolute;top: 1rem;right: 1rem">
      <div>
          <span style="line-height: 48px;">
            地铁找房 | 画圈找房
          </span>
      </div>
    </div>
    <div style="position: absolute;top: 1rem;left: 1rem">
      <div class="info">
        <div class="input-item">
          <div class="input-item-div">
            <img src="/favicon.ico" width="24" alt="">
          </div>
          <div class="input-item-div">
            <div class="input-item-div-anniu">
              <button class="input-item-div-anniu-button" @click="openCity()">
                宁波
                <el-icon size="12" style="color: rgb(185,185,185)">
                  <CaretBottom/>
                </el-icon>
              </button>
            </div>
          </div>
          <div class="input-item-div">
            <div class="input-item-div-anniu">
              <button class="input-item-div-anniu-button" @click="openTheCard(2)">
                二手房
                <el-icon size="12" style="color: rgb(185,185,185)">
                  <CaretBottom/>
                </el-icon>
              </button>
            </div>

          </div>
          <div class="input-item-div">
            <el-divider style="height: 20px" direction="vertical"/>
          </div>
          <div class="input-item-div input-item-div-frame" style="height: 24px">
            <div class="input-item-div-div">
              <input v-model="input" class="input-item-div-div-input" style="outline: none;" type="text"
                     placeholder="请输入您的目标地点">
            </div>
            <div class="input-item-div-div">
              <button class="input-item-div-div-button">
                <el-icon>
                  <Search/>
                </el-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="position: absolute;top: 1rem;left: 29rem">
      <div class="info">
        <div class="input-item">
          <div class="input-item-div-right">
            <div class="input-item-div-anniu">
              <button :class="'input-item-div-anniu-button '+(openTheCardAll.number === 3?'newStyle':null)"
                      @click="openTheCard(3)">
                价格
                <el-icon size="12" style="color: rgb(185,185,185)">
                  <CaretBottom/>
                </el-icon>
              </button>
            </div>
          </div>
          <div class="input-item-div-right">
            <div class="input-item-div-anniu">
              <button :class="'input-item-div-anniu-button '+(openTheCardAll.number === 4?'newStyle':null)"
                      @click="openTheCard(4)">
                房型
                <el-icon size="12" style="color: rgb(185,185,185)">
                  <CaretBottom/>
                </el-icon>
              </button>
            </div>
          </div>
          <div class="input-item-div-right">
            <div class="input-item-div-anniu">
              <button :class="'input-item-div-anniu-button '+(openTheCardAll.number === 5?'newStyle':null)"
                      @click="openTheCard(5)">
                面积
                <el-icon size="12" style="color: rgb(185,185,185)">
                  <CaretBottom/>
                </el-icon>
              </button>
            </div>
          </div>
          <div class="input-item-div-right">
            <div class="input-item-div-anniu">
              <button :class="'input-item-div-anniu-button '+(openTheCardAll.number === 6?'newStyle':null)"
                      @click="openTheCard(6)">
                更多
                <el-icon size="12" style="color: rgb(185,185,185)">
                  <CaretBottom/>
                </el-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="openTheCardAll.number > 2 || openTheCardAll.number ==0" style="position: absolute;top: 5rem;left: 1rem">
      <div class="info">
        <el-collapse style="border: none">
        <el-collapse-item style="width: 410px;">
          <template #title>
            <div style="width: 410px">
          <span style="line-height: 50px;">可视区域内为您找到<span
              style="color:rgba(88,141,248);">4151</span>套房子</span>
            </div>
          </template>
          <Htabs/>
        </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div v-if="openTheCardAll.number == 1" style="position: absolute;top: 5rem;left: 1rem">
      <el-scrollbar height="70vh">
        <div class="info" style="width: 410px">
          <div id="hover-click-after">
            <el-descriptions column="4" title="热门城市">
              <el-descriptions-item>
                北京
              </el-descriptions-item>
              <el-descriptions-item>
                上海
              </el-descriptions-item>
              <el-descriptions-item>
                广州
              </el-descriptions-item>
              <el-descriptions-item>
                深圳
              </el-descriptions-item>
              <el-descriptions-item>
                成都
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="国内城市"/>
            <ul style="list-style: none">
              <li style="display: flex;flex-direction:row;" v-for="m in citiesList">
                <div style="width: 20%;line-height: 33px;">{{ m.name }}:</div>
                <div style="width: 100%;display: flex;flex-wrap: wrap;">
                <span class="ul-li-div-span" v-for="n in m.districts">
                  {{ n.name }}
                </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <div v-if="openTheCardAll.number == 2" style="position: absolute;top: 5rem;left: 7rem">
      <div class="info" style="min-width: 100px;padding-left: 2px;padding-right: 2px ">
        <div id="hover-click-after">
          <button class="hover-click-after-button">二手房</button>
          <button class="hover-click-after-button">租房</button>
        </div>
      </div>
    </div>
    <div v-if="openTheCardAll.number > 2" style="position: absolute;top: 5rem;left: 29rem;width: 39rem;">
      <div class="info" style="padding: 1.5rem">
        <div id="hover-click-after">
          <div v-if="openTheCardAll.number == 3">
            <el-descriptions column="4" title="价格（万）">
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                40以下
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                40-60
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                60-80
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                80-100
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                100-150
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                150-200
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                200-300
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                300以上
              </el-descriptions-item>
            </el-descriptions>
            <el-divider/>
            <div>
              <input type="number" class="hover-click-after-number">
              -
              <input type="number" class="hover-click-after-number">
              (万)
            </div>
          </div>
          <div v-if="openTheCardAll.number == 4">
            <el-descriptions column="4" title="房型">
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                一室
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                二室
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                三室
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                四室
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                五室
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                五室以上
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <div v-if="openTheCardAll.number == 5">
            <el-descriptions column="4" title="面积（m²）">
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                50以下
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                50-70
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                70-90
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                90-120
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                120-150
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                150-200
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                200-300
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                300以上
              </el-descriptions-item>
            </el-descriptions>
            <el-divider/>
            <div>
              <input type="number" class="hover-click-after-number">
              -
              <input type="number" class="hover-click-after-number">
              (m²)
            </div>
          </div>
          <div v-if="openTheCardAll.number == 6">
            <el-descriptions column="4" title="房源特色">
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                必看好房
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                满五年
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                满两年
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                近地铁
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                VR房源
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                VR看装修
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                7日新上
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                随时看房
              </el-descriptions-item>
            </el-descriptions>

            <el-descriptions column="4" title="朝向">
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                南北
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                朝南
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                朝东
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                朝北
              </el-descriptions-item>
              <el-descriptions-item>
                <el-checkbox v-model="checked1" size="large"/>
                朝西
              </el-descriptions-item>

              <el-descriptions column="4" title="楼层">
                <el-descriptions-item>
                  <el-checkbox v-model="checked1" size="large"/>
                  低楼层
                </el-descriptions-item>
                <el-descriptions-item>
                  <el-checkbox v-model="checked1" size="large"/>
                  中楼层
                </el-descriptions-item>
                <el-descriptions-item>
                  <el-checkbox v-model="checked1" size="large"/>
                  高楼层
                </el-descriptions-item>
              </el-descriptions>
            </el-descriptions>


          </div>
          <div style="margin: 1rem 0;height: 0.2rem">
            <button class="hover-click-after-div-button" style="float: left">
              重置
            </button>
            <button class="hover-click-after-div-button" style="float: right;margin-left: 0.5rem">
              确定
            </button>
            <button class="hover-click-after-div-button" style="float: right">
              取消
            </button>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

import {defineComponent} from "vue";
import {H_button, CardTopSmall, BaiduMap,Htabs} from "@/components";

export default defineComponent({
  components: {H_button, CardTopSmall, BaiduMap,Htabs}
})
</script>
<script setup>
import {ref, computed,onBeforeMount } from "vue";
import {ElAmap, ElAmapLayerDefault,useCitySearch ,lazyAMapApiLoaderInstance} from "@vuemap/vue-amap";
import axios from "axios";
import {ElMessage} from "element-plus";

const center = ref([116.396845,39.921586]);
const openTheCardAll = ref({number: 0});

const openTheCard = (num) => {
  openTheCardAll.value.number = num;// 设置新样式的类名newStyle

}
onBeforeMount(async () => {
  await lazyAMapApiLoaderInstance; // 等待 AMap API 加载完成

  const citySearchInstance = await useCitySearch(); // 获取城市搜索实例

  const cityResult = await citySearchInstance.getLocalCity(); // 获取当前城市信息

  if (cityResult && cityResult.bounds) {
    center.value = cityResult.bounds.getCenter().toArray(); // 设置中心点
    console.log('cityResult:', cityResult); // 打印城市信息
  }
});

const citiesList = ref();

const openCity = () => {
  openTheCardAll.value.number = 1;// 设置新样式的类名newStyle
  axios.get("//restapi.amap.com/v3/config/district?key=88052472873d6f947ff79a0e98ae1cff&keywords=中国&subdistrict=2&extensions=base")
      .then((response) => {
        if (response.data.infocode == 10000) {
          citiesList.value = response.data.districts[0].districts;
        } else {
          ElMessage.error(response.data.info)
        }
      })
      .catch((error) => {
        requesterror(error);
      });
}

const requesterror = (error) => {
  if (error.response) {
    // 请求已发出，但服务器响应的状态码不在 2xx 范围内
    ElMessage.error("服务器无响应")
  } else if (error.request) {
    // 请求已发出，但没有收到任何响应
    ElMessage.error("服务器无响应")
  } else {
    // 在设置请求时触发了一个错误
  }
}


const zoom = ref(13);


</script>
<style scoped>

.ul-li-div-span {
  flex: 0 0 calc(25% - 10px); /* 假设每个span之间有10px的间隙 */
  margin: 5px; /* 设置margin来创建间隙 */
}

.inverted-triangle {
  width: 0;
  height: 0;
  border-left: 5px solid transparent; /* 左边 */
  border-right: 5px solid transparent; /* 右边 */
  border-top: 5px solid rgba(48, 114, 246); /* 底部 */
}

.map-container {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.newStyle {
  color: rgb(83, 164, 255);
}

.hover-click-after-button {
  padding: 0.6rem 1rem;
  background-color: rgba(255, 255, 255);
  border-radius: 0.3rem;
  cursor: pointer;
  transition-duration: .3s;
  border: none;
  display: flex;
  margin: 0.4rem auto;
  text-align: center;
  height: 3rem;
  line-height: 2rem;
  width: 100%;
}

.hover-click-after-button:hover {
  background-color: rgba(230, 230, 230);
}

.hover-click-after-div-button {
  padding: 0.4rem 1rem;
  background-color: rgba(255, 255, 255);
  border: 1px solid rgba(230, 230, 230, 0.87);
  border-radius: 0.3rem;
  cursor: pointer;
  transition-duration: .3s;
}

.hover-click-after-div-button:hover {
  border: 1px solid rgba(93, 157, 255, 0.87);
}

.hover-click-after-number {
  width: 100px;
  padding: 0.4rem;
  border: 1px solid rgba(230, 230, 230, 0.87);
  border-radius: 0.3rem;
  background-color: transparent;
  outline: none;
  transition-duration: .3s;
}

.hover-click-after-number:hover {
  border: 1px solid rgba(93, 157, 255, 0.87);
}

.input-item-div-anniu-button {
  border: none;
  background-color: transparent;
  font-size: 14px;
  cursor: pointer;
}

.input-item-div-anniu {

}

.BControl-button {
  padding: .8rem;
  display: block;
  border: none;
  background-color: rgba(255, 255, 255);
  margin-top: 0.5rem;
  border-radius: 0.3rem;
  cursor: pointer;
}

.BControl-button:hover {
  background-color: rgba(240, 240, 240);
}

.input-item-div-div-button {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0 0.3rem;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.input-item-div-frame {
  height: 22px;
  border-radius: 0.2rem;
  margin-right: 0.1rem !important;
  border: 1px solid rgba(0, 146, 236, 0);
  padding: 0 0 0 0.5rem;
  transition-duration: .3s;
}

.input-item-div-frame:hover {
  border: 1px solid rgba(0, 146, 236, 0.5);
}

.input-item-div {
  height: 19px;
  float: left;
  margin-right: 1rem;
}

.input-item-div-right {
  height: 19px;
  float: left;
  margin: 0 .75rem 0;
}

.input-item-div-div {
  float: left;
  line-height: 24px;
}

.input-item-div-div-input {
  border: none;
  background-color: transparent;
}


.input-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 3rem;
}

.info {
  padding: .1rem .75rem;
  border-radius: .25rem;
  background-color: white;
  min-width: 17rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
}

</style>
