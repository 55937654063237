<template>
  <el-row :gutter="10">
    <el-col :span="24" v-for="(comment,index) in data" :key="index">
      <el-divider/>
      <a-comment
          :author="comment.username"
          :avatar="comment.image"
          :content="comment.content"
          :datetime="comment.created"
          style="margin: 1rem 0 0 1rem"
      >
        <template #actions>
          <span class="action"> <IconMessage/> 回复 </span>
        </template>
        <el-row :gutter="10">
          <el-col :span="24" v-for="(subComment, subIndex) in comment.commentVO" :key="subIndex">
            <a-comment
                :author="subComment.username"
                :avatar="subComment.image"
                :content="subComment.content"
                :datetime="subComment.created"
            >
              <template #actions>
                <span class="action"> <IconMessage/> 回复 </span>
              </template>
              <el-row :gutter="10">
                <el-col :span="24" v-for="(thirdComment, thirdIndex) in subComment.commentVO" :key="thirdIndex">
                  <a-comment
                      :author="thirdComment.username"
                      :avatar="thirdComment.image"
                      :content="thirdComment.content"
                      :datetime="thirdComment.created"
                  >
                    <template #actions>
                      <span class="action"> <IconMessage/> 回复 </span>
                    </template>
                  </a-comment>
                </el-col>
              </el-row>
            </a-comment>
          </el-col>
        </el-row>
      </a-comment>
    </el-col>
  </el-row>
</template>

<script>
import {IconHeart, IconMessage, IconStar} from '@arco-design/web-vue/es/icon';
import axios from "axios";
import qs from "qs";

export default {
  components: {
    IconHeart,
    IconMessage,
    IconStar,
  },
  data() {
    return {
      data: null,
    };
  },


  created: function () {
    const wid = this.$route.params.id; // 确保 wid 在这里可用
    const url = MICRO_BLOG+`/v1/comment/selectByWeiboId?id=${wid}`;
    axios.get(url)
        .then((response) => {
          this.data = response.data.data;
          this.commentVO1 = response.data.data.commentVO
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
  }
};
</script>

<style scoped>
.action {
  display: inline-block;
  padding: 0 4px;
  color: var(--color-text-1);
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.1s ease;
}

.action:hover {
  background: var(--color-fill-3);
}
</style>
