<script setup>

</script>

<template>
  <div style="margin: .5rem auto; width: 100%;text-align: center;">
    <el-link ref="https://www.fivemhy.cn/" :underline="false">Fivem永恒社区 | 夜愿海</el-link> ( <el-link ref="https://beian.miit.gov.cn/" :underline="false">沪ICP备2023021842号-1</el-link> ) | 网站地图
    <br>
    <p>
      Copyright © 2023-2024丨Powered by HiloneyOS_v1.0
    </p>
  </div>

</template>

<style scoped>

</style>