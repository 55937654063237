<script>
import {ProductArr} from '@/components/constants.js';
export default {
  mounted() {
    ProductArr.value.forEach(product => {
      if (product.id == this.$route.params.id)this.product = product;
    });
  },
  data() {
    return {
      product: {}
    };
  },
};
</script>
<template>
  <el-card class="box-card" style="margin: 50px auto">
    <el-row :gutter="20">
      <el-col :span="9">
        <el-image style="width: auto;height: 450px;" :src="product.url" fit="cover"/>
      </el-col>
      <el-col :span="15">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-text tag="b" style="font-size: 26px">{{ product.title }}</el-text>
          </el-col>
          <el-col :span="24">
            <el-text type="danger" style="font-size: 20px">￥</el-text>
            <el-text type="danger" style="font-size: 38px">{{ product.price }} </el-text>
            <el-text tag="del" type="danger" style="font-size: 16px"> {{ product.oldPrice }}</el-text>
          </el-col>
          <el-col :span="24">
            <el-text>销量:  {{ product.saleCount }} </el-text>
          </el-col>
          <el-col :span="24" style="margin-top: 300px">
            <el-button-group>
              <el-button type="danger" round>
                立即购买
              </el-button>
              <el-button type="warning" round>
                加入购物车
              </el-button>
            </el-button-group>
            <el-divider direction="vertical" />
            <el-button type="info" text round><el-icon><Star/></el-icon>收藏</el-button>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </el-card>
</template>

<style scoped>

</style>